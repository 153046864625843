/* .datePicker {
  width: 50% !important;
  height: 50% !important;
  border: 1px solid green;
  font-size: 20px;
} */

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 38px;
  border: 1px solid rgb(209 213 219);
  border-radius: 5px;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}

.react-datepicker__input-container input:focus {
  border: 2px solid rgb(255 181 74);
}

.disabled input {
  background-color: rgb(229 231 235);
  cursor: not-allowed;
}

.selected-start-date {
  background-color: #90b5d2; /* You can set the background color as per your design */
  color: #ffffff !important; /* You can set the text color as per your design */
  border-radius: 5px;
}

.selected-start-date:hover {
  background-color: #1d5d90 !important; /* Change the background color on hover */
  color: #ffffff !important; /* You can set the text color as per your design */
  border-radius: 5px;
}
