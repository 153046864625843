@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.button {
  color: var(--text-basic);
}

html {
  min-width: 320px;
}

h5 {
  color: var(--text-grey);
}

body {
  background-attachment: fixed;
}

:root {
  --text-basic: #f0ecec;
  --text-grey: #b1b7be;
  --nav-size: 60px;
  --border: 1px solid #b1b7be;
  --border-bold: 3px solid #b1b7be;
  --border-color: #b1b7be;
  --border-radius: 8px;
  --speed: 500ms;
  --bg-color: #242526;
  --bg-image: linear-gradient(to bottom right, #243b54, #162133);
  --color-primary: #1e2f4f;
  --button-highlight: #5f84ac8a;
  --box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  --box-shadow-light: 0px 0px 20px rgba(0, 0, 0, 0.2);
  --nav-button: #484a4d;
  --button-radius: 25px;
}

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0;
}

a {
  color: var(--text-basic);
  text-decoration: none;
}

h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(110, 110, 110);
}

::-webkit-scrollbar-thumb:active {
  background: rgb(110, 110, 110);
}

.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  height: calc(100vh - 60px);
  min-height: 650px;
}

a:hover {
  text-decoration: none;
}

.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: rgb(70 70 158 / 30%);
  border: 1px dashed grey;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.button-border {
  border-bottom: 2px solid transparent; /* Set initial border color as transparent */
  transition: padding 10s ease-in-out; /* Transition the padding */
}

.border-thaleria-orange-500 {
  border-color: #ffc35a;
}

@layer utilities {
  .max-h-500 {
    max-height: 500px;
  }
  .max-h-550 {
    max-height: 550px;
  }

  .max-h-600 {
    max-height: 600px;
  }
  .max-h-650 {
    max-height: 650px;
  }
  .max-h-700 {
    max-height: 700px;
  }
  .max-h-750 {
    max-height: 750px;
  }
  .min-h-500 {
    min-height: 500px;
  }
  .min-h-550 {
    min-height: 550px;
  }

  .min-h-600 {
    min-height: 600px;
  }
  .min-h-650 {
    min-height: 650px;
  }
  .min-h-700 {
    min-height: 700px;
  }
  .min-h-750 {
    min-height: 750px;
  }
}
