.table-wrapper {
  overflow-x: auto;
  border-bottom: 1px solid #d1d5db; /* Gray separator */
}

.ap-table {
  width: 100%;
  border-collapse: collapse;
}

.ap-table-head-th {
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

.ap-table-body-td {
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

.custom-line-form {
  margin-top: 16px;
}

.custom-line-form form {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .custom-line-form form {
    grid-template-columns: 1fr 1fr;
  }
}
